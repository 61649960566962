import * as React from "react"
import { Link } from "gatsby"
import FooterWrapper, {
  FooterCol,
  Logo,
  Infos,
  FooterTitle,
  FooterContent,
  Menu,
} from './Footer.style';
import LogoImage from "../../images/trademate.svg"
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoYoutube,
} from 'react-icons/io';
import SocialProfile from "../SocialProfile/SocialProfile"

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/groups/tradematesportsbettingforum',
    tooltip: 'Trademate Facebook'
  },
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/tradematesports/',
    tooltip: 'Trademate Instagram'
  },
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/TrademateSports',
    tooltip: 'Trademate Twitter'
  },
  {
    icon: <IoLogoYoutube />,
    url: 'https://www.youtube.com/channel/UCwb59a_gp2TmoeJgl6xTHOg',
    tooltip: 'Trademate Youtube'
  },
];

const MenuItems = [
  { url: 'https://www.tradematesports.com/core', label: 'Trademate Core', external: true},
  { url: 'https://www.tradematesports.com/nordics', label: 'Trademate Nordics', external: true},
  { url: 'https://www.tradematesports.com/pro', label: 'Trademate Pro', external: true}
]

const MenuItems2 = [
  { url: 'https://www.tradematesports.com/about-us', label: 'About Us', external: true},
  { url: 'https://www.tradematesports.com/contact-us', label: 'Contact Us', external: true},
  { url: 'https://www.tradematesports.com/jobs', label: 'Jobs', external: true},
  { url: 'https://www.tradematesports.com/affiliates', label: 'Affiliates', external: true},
  { url: 'https://www.oddshero.io', label: 'Matched Betting - Oddshero', external: true}
]

const MenuItems3 = [
  { url: 'https://www.tradematesports.com/value-guarantee', label: 'Value Guarantee', external: true},
  { url: 'https://www.tradematesports.com/faq', label: 'FAQ', external: true},
  { url: 'https://www.tradematesports.com/terminology', label: 'Terminology', external: true},
  { url: 'https://www.tradematesports.com/privacy-policy', label: 'Privacy Policy', external: true},
  { url: 'https://www.tradematesports.com/cancellation-policy', label: 'Cancellation Policy', external: true},
  { url: 'https://www.tradematesports.com/responsible-gambling', label: 'Responsible Gambling', external: true}
]

type FooterProps = {
  children: React.ReactNode
}

const Footer: React.FunctionComponent<FooterProps> = ({
  children,
  ...props
}) => {
  return (
    <FooterWrapper>
      <FooterCol>
        <Logo>
          <Link to="/">
            <img
              src={LogoImage}
              alt="Tradematesports Logo"
              style={{
                height: '60px'
              }}
            />
          </Link>
        </Logo>

        <Infos>Nedre Vollgate 3, 0158 Oslo</Infos>
        <Infos>marius@tradematesports.com</Infos>
        <br />
        <SocialProfile items={SocialLinks} />
        <br />
        <br />
        <Infos>
          Copyright &copy; {new Date().getFullYear()} &nbsp;
          <a href="https://www.tradematesports.com/"> TM Sports Technologies AS</a>
        </Infos>
      </FooterCol>

      <FooterCol>
        <FooterTitle>Trademate Sports</FooterTitle>
        <FooterContent>
          {MenuItems.map((item, index) => (
            <Menu key={index}>
              {item.external ? (
                <a style={{color: 'black'}} href={item.url}>{item.label}</a>
              ) : (
                <Link to={item.url} activeClassName="active-link">
                  {item.label}
                </Link>
              )}
            </Menu>
          ))}
        </FooterContent>
      </FooterCol>

      <FooterCol>
        <FooterTitle>Company</FooterTitle>
        <FooterContent>
          {MenuItems2.map((item, index) => (
            <Menu key={index}>
              {item.external ? (
                <a style={{color: 'black'}} href={item.url}>{item.label}</a>
              ) : (
                <Link to={item.url} activeClassName="active-link">
                  {item.label}
                </Link>
              )}
            </Menu>
          ))}
        </FooterContent>

      </FooterCol>

      <FooterCol>
        <FooterTitle>Support</FooterTitle>
        <FooterContent>
          {MenuItems3.map((item, index) => (
            <Menu key={index}>
              {item.external ? (
                <a style={{color: 'black'}} href={item.url}>{item.label}</a>
              ) : (
                <Link to={item.url} activeClassName="active-link">
                  {item.label}
                </Link>
              )}
            </Menu>
          ))}
        </FooterContent>

      </FooterCol>
    </FooterWrapper>
  )
}

export default Footer
